var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('portal',{attrs:{"to":"title-page"}},[_c('h1',[_vm._v("Listado de tour")])]),(_vm.tour != null)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.tableHeaders,"items":_vm.tour,"search":_vm.search,"item-key":"id","fixed-header":""},scopedSlots:_vm._u([{key:"item.acciones",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{name: 'edit', params:{Id: item.id}}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"#212121"}},[_vm._v("mdi-pencil")])],1),_c('v-icon',{staticClass:"mr-2",attrs:{"color":"red"},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("mdi-delete")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({on:{"click":function($event){return _vm.$router.push({path:("/backend/admin/tours/" + (item.id) + "/rates")})}}},'v-icon',attrs,false),on),[_vm._v(" mdi-currency-usd ")])]}}],null,true)},[_c('span',[_vm._v("Ver tarifas")])])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"rounded":"","small":"","color":item.status == 'active' ? 'green' : 'red',"dark":""},on:{"click":function($event){return _vm.setItem(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.mdiPencil))]),_vm._v(" "+_vm._s(item.status)+" ")],1)]}},{key:"top",fn:function(){return [_c('div',{staticClass:"table-top-bar"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4","sm":"12"}},[_c('v-text-field',{attrs:{"prepend-icon":"mdi-magnify ","label":"Search","color":"#f88511","dense":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{staticClass:"offset-md-2",attrs:{"cols":"12","md":"6","sm":"12"}},[_c('router-link',{attrs:{"to":{ path: 'tours/create' }}},[_c('v-btn',{attrs:{"dark":"","small":"","color":"#f88511"}},[_c('v-icon',[_vm._v("mdi-plus-thick")]),_vm._v("Tour ")],1)],1)],1)],1)],1)]},proxy:true}],null,false,2092991747),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}):_vm._e(),_c('v-snackbar',{attrs:{"color":_vm.snackbar.color},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"","text":""},on:{"click":function($event){_vm.snackbar.show = false}}},'v-btn',attrs,false),[_vm._v("Cerrar")])]}}]),model:{value:(_vm.snackbar.show),callback:function ($$v) {_vm.$set(_vm.snackbar, "show", $$v)},expression:"snackbar.show"}},[_vm._v(" "+_vm._s(_vm.snackbar.message)+" ")]),_c('v-row',[(_vm.selectedTour)?_c('v-dialog',{attrs:{"max-width":"450px"},model:{value:(_vm.dialogStatus),callback:function ($$v) {_vm.dialogStatus=$$v},expression:"dialogStatus"}},[_c('v-card',[_c('v-card-title',[_vm._v("Status")]),_c('v-divider'),_c('v-card-text',[_c('v-select',{attrs:{"items":_vm.selectStatus,"item-text":"text","label":"Status","item-value":"value"},model:{value:(_vm.selectedTour.status),callback:function ($$v) {_vm.$set(_vm.selectedTour, "status", $$v)},expression:"selectedTour.status"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{on:{"click":function($event){return _vm.closeDialogStatus()}}},[_vm._v("Close")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.statusItem()}}},[_vm._v("Guardar")])],1)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }