<template>
  <div>
    <portal to="title-page">
      <h1>Listado de tour</h1>
    </portal>
    <v-data-table
      v-if="tour != null"
      v-model="selected"
      :headers="tableHeaders"
      :items="tour"
      :search="search"
      item-key="id"
      fixed-header
      class="elevation-1"
    >
      <!-- acciones -->
      <template v-slot:item.acciones="{ item }">
        <router-link :to="{name: 'edit', params:{Id: item.id}}">
          <v-icon class="mr-2" color="#212121">mdi-pencil</v-icon>
        </router-link>
        <v-icon class="mr-2" color="red" @click="deleteItem(item)">mdi-delete</v-icon>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              @click="$router.push({path:`/backend/admin/tours/${item.id}/rates`})"
            >
              mdi-currency-usd
            </v-icon>
          </template>
          <span>Ver tarifas</span>
        </v-tooltip>
      </template>
      <template v-slot:item.status="{ item }">
        <v-btn @click="setItem(item)"
          rounded 
          small :color="item.status == 'active' ? 'green' : 'red'"
          dark>
          <v-icon small>{{mdiPencil}}</v-icon>
           {{ item.status }}
        </v-btn>
      </template>
      <!--seccion  add tour y buscar -->
      <template v-slot:top>
        <div class="table-top-bar">
          <v-row>
            <v-col cols="12" md="4" sm="12">
              <v-text-field
                v-model="search"
                prepend-icon="mdi-magnify "
                label="Search"
                color="#f88511"
                dense
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="offset-md-2" md="6" sm="12">
              <router-link :to="{ path: 'tours/create' }">
                <v-btn dark small color="#f88511">
                  <v-icon>mdi-plus-thick</v-icon>Tour
                </v-btn>
              </router-link>
            </v-col>
          </v-row>
        </div>
      </template>
    </v-data-table>
    <v-snackbar v-model="snackbar.show" :color="snackbar.color">
      {{snackbar.message}}
      <template v-slot:action="{ attrs }">
        <v-btn color text v-bind="attrs" @click="snackbar.show = false">Cerrar</v-btn>
      </template>
    </v-snackbar>
    <v-row>
      <v-dialog v-model="dialogStatus" max-width="450px" v-if="selectedTour">
        <v-card>
          <v-card-title>Status</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-select
              v-model="selectedTour.status"
              :items="selectStatus"
              item-text="text"
              label="Status"
              item-value="value"
            ></v-select>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn  @click="closeDialogStatus()">Close</v-btn>
            <v-btn color="primary" @click="statusItem()">Guardar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>
<script>
//import Vue from "vue";
import Tour from "@/models/Tour.js";
import { mdiPencil } from "@mdi/js";
export default {
  name: "Tours",
  data() {
    return {
      mdiPencil: mdiPencil,
      itemIdStatus: "",
      dialogStatus: false,
      selectStatus: [
        { value: "active", text: "Active" },
        { value: "inactive", text: "Inactive" },
      ],
      statusTour: "",
      snackbar: {
        show: false,
        message: "",
        color: "",
      },
      tourModel: new Tour(),
      tour: null,
      selected: [],
      search: "",
      tableHeaders: [
        {
          text: "Nombre",
          align: "start",
          sortable: true,
          value: "name",
        },
        {
          text: "Titulo",
          align: "start",
          sortable: false,
          value: "title",
        },
        // {
        //   text: "Descripcion",
        //   align: "start",
        //   sortable: false,
        //   value: "description",
        // },
        {
          text: "Status",
          align: "start",
          sortable: true,
          value: "status",
        },
        {
          text: "Acciones",
          sortable: false,
          value: "acciones",
        },
      ],
      dialog: {
        show: false,
        title: "Agregar categoria",
        textSave: "Agregar",
      },
      editar: false,
      selectedTour:null
    };
  },
  methods: {
    editItem(item) {
      console.log(item);
    },
    deleteItem(item) {
      // console.log(item);
      let statusdelete = item.statusdelete == 1 ? 0 : 1;
      //this.dialog.show = true;
      this.$dialog
        .confirm(`¿Desea eliminar el tour <strong>${item.name}</strong>?`, {
          loader: true,
        })
        .then((dialog) => {
          this.tourModel
            .update(item.id, { deleted: statusdelete })
            .then(async () => {
              await this.tourModel.deleteUrls(item.id)
              dialog.close();
              this.dialog.show = false;
              this.snackbar.show = true;
              this.snackbar.color = "green";
              this.snackbar.message = `El tour se elimino correctamente`;
            })
            .catch((error) => {
              console.error("Error removing document: ", error);
              dialog.close();
              this.snackbar.show = true;
              this.snackbar.color = "red";
              this.snackbar.message = `Error al eliminar el tour, por favor contacte al Administrador si el problema persiste`;
            });
        })
        .catch(() => {
          console.log("Delete aborted");
        });
    },

    statusItem() {
      let loading = this.$loading.show();
      // console.log("hola", changeStatus);
      // console.log("hola", this.itemIdStatus);
      this.tourModel
        .update(this.selectedTour.id, { status: this.selectedTour.status })
        .then(() => {
          loading.hide();
          this.snackbar.show = true;
          this.snackbar.color = "green";
          this.snackbar.message = `El tour se actualizo correctamente`;
          this.dialogStatus = false;
        })
        .catch((error) => {
          console.error("Error removing document: ", error);
          this.snackbar.show = true;
          this.snackbar.color = "red";
          this.snackbar.message = `Error al cambiar el status del tour, por favor contacte al Administrador si el problema persiste`;
        });
    },
    setItem(item) {
      //this.itemIdStatus = item;
      this.selectedTour = {... item};
      this.dialogStatus = true;
    },
    closeDialogStatus() {
      this.dialogStatus = false;
    },
  },
  mounted() {
    this.tourModel.all().onSnapshot((qsnap) => {
      this.tour = [];
      qsnap.forEach((doc) => {
        let tours = doc.data();
        tours.id = doc.id;
        this.tour.push(tours);
      });
    });
  },
  created() {
    this.$store.commit("SET_LAYOUT", "admin");
  },
};
</script>
<style>
.table-top-bar {
  text-align: right;
  padding: 16px 16px 0px 16px;
}
a {
  text-decoration: none;
  /* quita el subrayado de routerlink */
}
</style>